<template>
  <div class="container-page big">
    <section class="page-list-agreements">
      <div class="title-block-top">
        <div class="title-block-top__first m-0">
          <h2 class="title title--h2 title--analytics-detail">
            Перечень соглашений между правительством Российской Федерации и правительствами иностранных государств о
            поощрении и взаимной защите капиталовложений
          </h2>
        </div>
        <div class="title-block-top__second">
          <ButtonComponent @click="editCard(0)" mod="gradient-bg">Создать</ButtonComponent>
        </div>
      </div>
      <div class="mb-16">
        <DefaultFilter :filterValue="filter" @loadFilter="onLoadFilter" />
      </div>

      <div class="page-list-agreements__filter">
        <div class="page-list-agreements__filter-block">
          <div class="page-list-agreements__filter-item">
            <p class="label-field">Государство</p>
            <SelectMultiComponent
              v-model="filter.countryId"
              :options="getListCountriesAgreements"
              :settings="select2Settings"
              placeholder="Выберите"
              classSelect="multiselect--rectangular"
              class="visible-clear"
              @select="changeFilter('country')"
            />
          </div>
          <div class="page-list-agreements__filter-item">
            <p class="label-field">Группа государств</p>
            <SelectMultiComponent
              v-model="filter.countryGroupId"
              :options="getListCountriesGroupAgreements"
              :settings="select2Settings"
              placeholder="Выберите"
              classSelect="multiselect--rectangular"
              class="visible-clear"
              @select="changeFilter('countryGroup')"
            />
          </div>
        </div>
        <div class="page-list-agreements__filter-block">
          <div class="page-list-agreements__filter-item">
            <p class="label-field">Дата подписания</p>
            <dateComponent
              label="От"
              v-model="filter.signingDateFrom"
              modClass="redesign-type-exponential"
              dateIcon
              :upperLimit="upperLimitFrom"
            />
          </div>
          <div class="page-list-agreements__filter-item">
            <dateComponent
              label="До"
              v-model="filter.signingDateTo"
              modClass="redesign-type-exponential"
              dateIcon
              :upperLimit="upperLimitTo"
              :lowerLimit="lowerLimitTo"
            />
          </div>
        </div>
      </div>

      <div>
        <div class="table-container-base">
          <table>
            <thead>
              <tr>
                <th class="th-grey column-icon"></th>
                <th class="th-grey column-agreements-country">Страна</th>
                <th class="th-grey column-agreements-country-group">Группа стран</th>
                <th class="th-grey column-agreements-signing-date">Подписание</th>
                <th class="th-grey column-agreements-ratification-date">
                  Ратификация Российской Стороной (или подписание соответствующего федерального закона)
                </th>
                <th class="th-grey column-agreements-introduction">Вступление в силу</th>
                <th class="th-grey column-agreements-published">Опубликовано</th>
                <th class="th-grey column-agreements-from-government">От имени правительства</th>
                <th class="th-grey column-agreements-document">Документ</th>
              </tr>
            </thead>
            <tbody v-if="getAgreementsPage.items?.length && !isLoading">
              <tr v-for="item in getAgreementsPage.items" :key="item.id" @dblclick="editCard(item.id)">
                <td class="column-icon">
                  <div class="wrap-icon">
                    <IconComponent class="icon--full-svg" @click.prevent.stop="editCard(item.id)" name="edit" />
                    <IconComponent
                      class="icon--full-svg delete"
                      @click.prevent.stop="onEventDelete(item.id)"
                      name="delete-not-fill"
                    />
                  </div>
                </td>
                <td class="column-agreements-country">{{ item.country?.name || '' }}</td>
                <td class="column-agreements-country-group">{{ item.countryGroup?.name }}</td>
                <td class="column-agreements-signing-date">
                  <template v-if="item.signingDate">
                    {{ $momentFormat(item.signingDate, 'DD.MM.YYYY') }}
                  </template>
                  <template v-else> - </template>
                </td>
                <td class="column-agreements-ratification-date">
                  <template v-if="item.ratificationDate">
                    {{ $momentFormat(item.ratificationDate, 'DD.MM.YYYY') }}
                  </template>
                  <template v-else> - </template>
                </td>
                <td class="column-agreements-introduction">
                  <template v-if="item.takeEffectDate">
                    {{ $momentFormat(item.takeEffectDate, 'DD.MM.YYYY') }}
                  </template>
                  <template v-else> - </template>
                </td>
                <td class="column-agreements-published">{{ item.isPublished ? 'Да' : 'Нет' }}</td>
                <td class="column-agreements-from-government">{{ item.governmentType.name }}</td>
                <td class="column-agreements-document">
                  <p v-if="item.agreementFile" @click="downloadFile(item)" class="page-list-agreements__link">
                    <IconComponent name="document-redesign" class="icon-document" />
                    <span v-if="item.agreementFile?.originalName" class="blue-dark">{{
                      item.agreementFile.originalName
                    }}</span>
                  </p>
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="isLoading">
              <tr>
                <td class="text-center" colspan="16">Загрузка...</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="text-center" colspan="16">Нет данных.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <template v-if="getAgreementsPage.pagesTotal > 1">
          <PaginationRedesigned
            :page-count="getAgreementsPage.pagesTotal"
            :current-page="getAgreementsPage.pageNumber"
            @change="onPageChange($event, false)"
            @more="onPageChange($event, true)"
          />
        </template>
      </div>
    </section>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { debounce } from 'lodash';
  import moment from 'moment';

  import dateComponent from '@/common/components/dateComponent.vue';
  import DefaultFilter from '@/common/components/DefaultFilter';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import PaginationRedesigned from '@/common/components/redesigned/PaginationRedesigned';
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import IconComponent from '@/common/ui/IconComponent';
  import Utils from '@/common/utils';

  import {
    INVESTMENT_AGREEMENT_ACTIONS_NAME,
    INVESTMENT_AGREEMENT_GETTERS_NAME,
  } from '../store/investmentAgreement/nameModule';

  export default {
    name: 'InvestmentAgreementRegistry',
    components: {
      IconComponent,
      DefaultFilter,
      PaginationRedesigned,
      SelectMultiComponent,
      dateComponent,
      ButtonComponent,
    },
    mixins: [filtermanager],
    data() {
      return {
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        filter: {
          countryId: null,
          countryGroupId: null,
          signingDateFrom: null,
          signingDateTo: null,
        },
        select2Settings: Constants.select2Settings,
        isLoading: false,
        upperLimitTo: new Date(),
      };
    },
    created() {
      this.loadFilter().then((response) => {
        let filter = response;
        if (filter) {
          this.filter = filter;
        }
        this.loadDictionaries();
        this.loadPage(false);
      });
    },
    computed: {
      ...mapGetters({
        getListCountriesAgreements: INVESTMENT_AGREEMENT_GETTERS_NAME.getListCountriesAgreements,
        getListCountriesGroupAgreements: INVESTMENT_AGREEMENT_GETTERS_NAME.getListCountriesGroupAgreements,
        getAgreementsPage: INVESTMENT_AGREEMENT_GETTERS_NAME.getAgreementsPage,
      }),
      upperLimitFrom() {
        if (this.filter.signingDateTo) {
          return moment(this.filter.signingDateTo, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
        }
        return new Date();
      },
      lowerLimitTo() {
        return moment(this.filter.signingDateFrom, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
      },
    },
    watch: {
      'filter.countryId'(val) {
        if (!val) this.changeFilter('country');
      },
      'filter.countryGroupId'(val) {
        if (!val) this.changeFilter('countryGroup');
      },
      'filter.signingDateFrom'() {
        this.changeFilter('dateFrom');
      },
      'filter.signingDateTo'() {
        this.changeFilter('dateTo');
      },
    },
    methods: {
      ...mapActions({
        getCountriesAgreements: INVESTMENT_AGREEMENT_ACTIONS_NAME.getCountriesAgreements,
        getCountriesGroupAgreements: INVESTMENT_AGREEMENT_ACTIONS_NAME.getCountriesGroupAgreements,
        getListAgreements: INVESTMENT_AGREEMENT_ACTIONS_NAME.getListAgreements,
        delete: INVESTMENT_AGREEMENT_ACTIONS_NAME.deleteAgreements,
      }),

      async loadPage(append) {
        const params = Object.assign(this.filter, this.request);
        this.isLoading = !append;
        let res = {};
        if (append) {
          res = await this.getListAgreements({ data: params, join: true });
        } else {
          res = await this.getListAgreements({ data: params });
        }
        if (res.status >= 200 && res.status < 300) {
          this.isLoading = false;
        }
      },
      loadDictionaries() {
        this.getCountriesAgreements({ countryGroupId: this.filter.countryGroupId });
        this.getCountriesGroupAgreements({ countryId: this.filter.countryId });
      },
      editCard(id) {
        this.$router.push({ name: 'InvestmentAgreementCard', params: { id: id, action: 'edit' } });
      },
      onEventDelete(id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            this.deleteCard(id);
          }
        });
      },
      async deleteCard(id) {
        const res = await this.delete({ id });
        if (res.status >= 200 && res.status < 300) {
          Constants.alert.fire('Удаление', 'Запись удалена', 'success');
          this.loadPage();
        } else {
          Constants.alert.fire('Удаление', 'Запись не удалена.', 'error');
        }
      },
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      changeFilter: debounce(function (type) {
        if (type === 'country') {
          this.getCountriesGroupAgreements({ countryId: this.filter.countryId });
        } else if (type === 'countryGroup') {
          this.getCountriesAgreements({
            countryGroupId: this.filter.countryGroupId,
          });
        }
        this.request.pageNumber = 1;
        this.saveFilter(this.filter);
        this.loadPage(false);
      }, 500),
      onLoadFilter(item) {
        this.filter = item;
        this.changeFilter();
      },
      downloadFile(item) {
        return Utils.downloadFile(`/api/file/download?id=${item.agreementFile.id}`, item.agreementFile.originalName);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/table/table-base.scss';
  @import '@/assets/theme/default/title.scss';

  .page-list-agreements {
    &__title {
      max-width: 740px;
      margin-bottom: 40px;
    }

    &__filter {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -9px 24px;

      &-item {
        width: calc(50% - 18px);
        margin: 0 9px 16px;
      }

      &-block {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        width: 50%;
      }
    }

    &__link {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      width: 100%;

      &:hover {
        span {
          text-decoration: underline;
        }
      }

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 16px);
        font-size: 15px !important;
        line-height: 20px !important;
      }
    }

    @media (max-width: $tablet) {
      &__title {
        margin-bottom: 24px;
      }

      &__filter {
        margin: 0 0 8px;

        &-item {
          width: 344px;
          margin: 0 16px 24px 0;

          &:last-child {
            margin-right: 0;
          }
        }

        &-block {
          width: 100%;
        }
      }
    }

    @media (max-width: $mobile) {
      &__filter {
        &-block {
          width: 100%;
        }

        &-item {
          width: 100%;
          margin: 0 0 16px 0;
        }
      }
    }
  }
</style>
